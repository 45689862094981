import client from 'apollo';
import api from 'common/api';
import { TOKEN, USER, USER_ID } from 'common/constant';
import React, { createContext, useReducer } from 'react';
import * as ActionTypes from './common/actionTypes';

let isServer = typeof window === 'undefined' ? false : true;

const getLoggedInUser = () => {
  let loggedInUser = process.browser ? localStorage.getItem(USER) : '';
  loggedInUser = loggedInUser ? JSON.parse(loggedInUser) : null;
  return loggedInUser;
};

const getUserId = () => {
  return isServer
    ? localStorage.getItem(USER_ID)
      ? parseInt(localStorage.getItem(USER_ID), 10)
      : ''
    : '';
};

const getUserEmail = () => {
  return isServer && localStorage?.getItem('current_email');
};

const initialState = {
  currentUser: getLoggedInUser() || {},
  userId: getUserId(),
  authToken: isServer ? localStorage.getItem(TOKEN) : '',
  authenticated: false,
  currentEmail: getUserEmail() || null
};

const reducer = (state, action) => {
  switch (action.type) {
    //! USER
    case ActionTypes.SET_CURRENT_USER:
      // eslint-disable-next-line no-case-declarations
      const user = action.data || {};
      // eslint-disable-next-line no-unused-expressions
      typeof window !== undefined
        ? localStorage.setItem(
            'USER',
            user && Object.keys(user).length ? JSON.stringify(user) : null
          )
        : '';
      return { ...state, currentUser: { ...user } };
    case ActionTypes.SET_USER_ID:
      // eslint-disable-next-line no-unused-expressions
      typeof window !== undefined
        ? localStorage.setItem('USER_ID', action.data)
        : '';
      return { ...state, userId: action.data };
    case ActionTypes.SET_AUTHENTICATED:
      return { ...state, authenticated: action.data };
    case ActionTypes.SET_TOKEN:
      localStorage.setItem('TOKEN', action.data);
      return { ...state, authToken: action.data };
    //! LOGOUT
    case ActionTypes.LOGOUT:
      delete api.defaults.headers.common.Authorization;
      localStorage.clear();
      client.clearStore();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        currentUser: {}
      };
    case ActionTypes?.SET_CURRENT_EMAIL:
      if (action?.data) {
        localStorage.setItem('current_email', action.data);
      }
      return { ...state, currentEmail: action.data };
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {}
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () => {
    return typeof window !== undefined ? localStorage.getItem('TOKEN') : null;
  };

  // eslint-disable-next-line
  const getCurrentUser = () => {
    return typeof window !== undefined
      ? localStorage.getItem('USER')
        ? JSON.parse(localStorage.getItem('USER'))
        : {}
      : '';
  };

  const initializeAuth = (authToken, userData) => {
    const token = authToken || getToken();
    const user = userData || getCurrentUser();
    const userId = getUserId();
    if (token) {
      api.defaults.headers.common = {
        Authorization: `Bearer ${token}`
      };
      dispatch({ type: ActionTypes.SET_TOKEN, data: token });
      dispatch({ type: ActionTypes.SET_AUTHENTICATED, data: true });
      dispatch({ type: ActionTypes.SET_CURRENT_USER, data: user });
      dispatch({ type: ActionTypes.SET_USER_ID, data: userId });
    }
  };

  const value = {
    state,
    dispatch,
    initializeAuth,
    getToken
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
