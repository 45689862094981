import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/nextjs';
import { TOKEN } from 'common/constant';
import { createBrowserHistory } from 'history';
import get from 'lodash/get';
import isObject from 'lodash/isObject';

const history = typeof window !== 'undefined' && createBrowserHistory();
let disableToastTimeout = null;
export const cacheData = new InMemoryCache();

const httpLink = createHttpLink({
  uri: process?.env?.NEXT_PUBLIC_APP_SERVER_URL
});

const authLink = setContext((ctx, { headers }) => {
  // eslint-disable-next-line no-undef
  const userToken = localStorage.getItem(TOKEN);
  let newHeaders = headers || {};

  newHeaders = {
    ...newHeaders,
    Authorization: userToken ? `Bearer ${userToken}` : ''
  };

  return {
    headers: newHeaders
  };
});

const responseMessageLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const { data } = response;

    if (
      data &&
      isObject(data) &&
      Object.keys(data).length > 0 &&
      data[`${Object.keys(data)[0]}`] &&
      data[`${Object.keys(data)[0]}`].message
    ) {
      if (Object.keys(data)[0] === 'forgotUserPassword') {
        if (data[`${Object.keys(data)[0]}`].status !== 'ERROR') {
          // setTimeout(() => {
          //   notificationToast({
          //     message:
          //       data[`${Object.keys(data)[0]}`].message ||
          //       "Operation successful",
          //     type: data[`${Object.keys(data)[0]}`].status,
          //   });
          // }, 1000);
        }
      } else {
        setTimeout(() => {
          const oResponse = data[`${Object.keys(data)[0]}`];
          if (!response) {
            return;
          }
          // notificationToast({
          //   message: oResponse.message || "Operation successful",
          //   type: oResponse.status === "ERROR" ? "error" : "success",
          // });
        }, 1000);
      }
    }
    return response;
  });
});

const errorLink = new onError((options) => {
  const { graphQLErrors, networkError, response } = options;

  if (networkError && networkError.statusCode === 405) {
    if (disableToastTimeout) {
      clearTimeout(disableToastTimeout);
    }

    disableToastTimeout = setTimeout(() => {
      if (networkError.result && networkError.result.message) {
        // notificationToast({
        //   message: networkError.result.message,
        //   type: "error",
        // });
      }
    }, 200);

    history.replace('/logout');
    return;
  }

  if (graphQLErrors && graphQLErrors.length > 0) {
    const isForBidden =
      get(graphQLErrors[0], 'extensions.code') === 'FORBIDDEN';
    if (!isForBidden) {
      // notificationToast({
      //   message: graphQLErrors[0].message,
      //   type: "error",
      // });
    }
  } else {
    setTimeout(() => {
      // notificationToast({
      //   message: "Something went wrong!",
      //   type: "error",
      // });
    }, 1000);
  }

  if (response) {
    response.errors.map((error) => {
      const { message: errorMessage, locations, path, extensions } = error;

      Sentry.captureException(
        new Error(
          `[Response error]: Message: ${errorMessage}, Location: ${locations}, Path: ${path}`
        )
      );

      if (extensions && extensions.code === 'FORBIDDEN') {
        history.replace('/access-denied');
      }

      if (
        extensions &&
        (extensions.code === 'UNAUTHENTICATED' || extensions.code === 405)
      ) {
        history.replace('/logout');
      }

      // eslint-disable-next-line no-console
      return console.log(
        `[Response error]: Message: ${errorMessage}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    Sentry.captureException(new Error(`[Network error]: ${networkError}`));
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  cache: cacheData,
  link: from([responseMessageLink, errorLink, authLink, httpLink])
});

//! keeping this for later use if required
// export const subscriptionClient = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: from([subscriptionLink]),
// });

export default client;
