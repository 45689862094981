export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  EMAIL: /^[a-z0-9.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
};

export const OTP_NUM_INPUT = 6;

export const SCAN_LIMIT = 1000;
export const SCAN_PRICE = 0.03;
export const USER_LIMIT = 10;

/* Authentication */
export const TOKEN = 'TOKEN';
export const USER = 'USER';
export const ADMIN = 'SUPER_ADMIN';
export const USER_ID = 'USER_ID';

/* ROUTERS  */
export const ROUTES = {
  LANDING: '/',
  LOGIN: '/auth/login',
  DETAIL: '/auth/detail',
  DASHBOARD: '/dashboard',
  SUCCESS: '/success',
  CANCEL: '/cancel',
  LOGOUT: '/logout'
};

export const SUBSCRIPTION_ID = 'bb97ccc1-833b-496b-a5e5-6bd4acda177d';

export const TERMS_AND_SERVICES = 'https://www.logicwind.com/terms-condition';
export const PRIVACY_POLICY = 'https://www.logicwind.com/privacy';
export const CONTACT_US = 'https://www.logicwind.com/contact';
