import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN =
  process?.env?.NEXT_APP_SENTRY_DSN ||
  'https://8e23ed7327b1481bb77267f61d26c32c@sentry.logicwind.co//77';
const APP_ENV = process?.env?.NEXT_APP_ENV || 'dev';

Sentry.init({
  tracesSampleRate: 1.0,
  dsn: SENTRY_DSN,
  environment: APP_ENV
});
